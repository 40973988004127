// REACT IMPORTS
import React, { useState, useEffect } from "react";
// METER IMPORTS FOR SKILL LEVEL SVG
import meter75 from "../assets/img/meter75.png";
import meter85 from "../assets/img/meter85.png";
import meter65 from "../assets/img/meter65.png";

// CAROUSEL TO DISPLAY THE SKILL LEVEL
import Carousel from "react-multi-carousel";
// CAROUSEL CSS
import "react-multi-carousel/lib/styles.css";
// COLOURFUL IMAGE FOR BG EFFECTS
import colorSharp from "../assets/img/color-sharp.png";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
// TRACK VISIBILITY FOR THE CHILD COMPONENT
import TrackVisibility from "react-on-screen";

export const Skills = () => {
  // SCREEN HEIGHTS AND WIDTHS
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // Add a state variable to track visibility
  const [isVisible, setIsVisible] = useState(false);

  // Use useEffect to set isVisible when the component mounts
  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <TrackVisibility>
                {() => (
                  <div
                    className={
                      isVisible ? "animate__animated animate__fadeIn" : ""
                    }
                  >
                    <h2>Skills</h2>
                    <br />
                    <Tab.Container id="projects-tabs" defaultActiveKey="CODING">
                      <Nav
                        variant="pills"
                        className="nav-pills mb-5 justify-content-center align-items-center"
                        id="pills-tab"
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="CODING">CODING</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="SPORTS">SPORTS</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content
                        id="slideInUp"
                        className={
                          isVisible
                            ? "animate__animated animate__slideInUp"
                            : ""
                        }
                      >
                        <Tab.Pane eventKey="CODING">
                          <Row>
                            <h1>CODING</h1>
                            <p>
                              My skill set includes HTML, CSS, JavaScript,
                              MySQL, React Native, React Js, Node.js And MySQL.
                              <br />
                              The frequent coding languages I use are also the
                              languages where I am strong.
                            </p>
                            <Carousel
                              responsive={responsive}
                              infinite={true}
                              className="owl-carousel owl-theme skill-slider"
                            >
                              <div className="item">
                                <img src={meter75} alt="Image" />
                                <h5>HTML & CSS</h5>
                              </div>
                              <div className="item">
                                <img src={meter85} alt="Image" />
                                <h5>React Js</h5>
                              </div>
                              <div className="item">
                                <img src={meter85} alt="Image" />
                                <h5>React Native</h5>
                              </div>
                              <div className="item">
                                <img src={meter75} alt="Image" />
                                <h5>Node Js</h5>
                              </div>
                              <div className="item">
                                <img src={meter65} alt="Image" />
                                <h5>MySQL</h5>
                              </div>
                              <div className="item">
                                <img src={meter85} alt="Image" />
                                <h5>JavaScript</h5>
                              </div>
                            </Carousel>
                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="SPORTS">
                          <div className="row">
                            <div className="col-md-6 d-flex flex-column">
                              <h3>Cricket</h3>
                              <p>
                                I am a dedicated cricketer excelling in the
                                competitive 5th division cricket league. With a
                                proven track record of leadership, I proudly
                                captained my school's under 10, under 12, and
                                under 14 cricket teams, securing two
                                championship trophies. Renowned as an
                                all-rounder, I consistently deliver strong
                                performances in bowling, batting, and fielding.
                                My commitment to excellence and versatility
                                positions me as a valuable asset on any
                                cricketing platform.
                              </p>
                            </div>
                            <div className="col-md-6 d-flex flex-column">
                              <div
                                className="vertical-line"
                                style={{
                                  borderLeft: "1px solid tomato",
                                  height: "100%",
                                  margin: "0 15px",
                                }}
                              ></div>
                              <h3>Karate</h3>
                              <p>
                                I have a strong background in Karate,
                                representing India in the prestigious All India
                                Karate Tournament where I clinched the first
                                position, showcasing my exceptional martial arts
                                prowess. Additionally, I have secured victory in
                                the State Championship and achieved numerous
                                accolades throughout my Karate journey. My
                                dedication to the art form is unwavering, and I
                                continue to train rigorously to refine my
                                techniques and maintain my status as a top
                                martial artist. With a relentless spirit and a
                                commitment to excellence, I am proud of my
                                achievements in Karate, which have shaped me
                                into a disciplined and skilled practitioner.
                              </p>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                )}
              </TrackVisibility>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};
