// REACT IMPORTS
import React from "react";
// BOOTSTRAP CSS
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
// PROJECT CARD
import { ProjectCard } from "./ProjectCard";
// PROJECT IMAGES
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
// GAME IMAGES
import gameImg1 from "../assets/img/game-img1.png";
import gameImg2 from "../assets/img/game-img2.png";
import gameImg3 from "../assets/img/game-img3.png";
// OTHER PROJECT IMAGES
import buisnessPrImg1 from "../assets/img/otherPrImg1.png";
// EXHANCING COLOUR BG
import colorSharp2 from "../assets/img/color-sharp2.png";
// CSS FOR ANIMATION
import "animate.css";
// TRACKVISIBILITY FOR CHILD COMPONENT
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [
    {
      title: "Chat Application",
      description: "Connect With Other's",
      imgUrl: "https://koushik.stsltd.in/k-chat/favicon.ico",
      link: "https://koushik.stsltd.in/k-chat",
    },
    {
      title: "Expense Tracker",
      description: "Track Spend Effortlessly",
      imgUrl: projImg2,
      link: "https://koushik.stsltd.in/expence-tracker",
    },
    {
      title: "Currency Convertor",
      description: "Convert Wizard",
      imgUrl: projImg3,
      link: "https://koushik.stsltd.in/currency/convertor.html",
    },
  ];

  const games = [
    {
      title: "Tic Tac Toe",
      description: "Two Player Game",
      imgUrl: gameImg1,
      link: "https://koushik.stsltd.in/tic-tac-toe/index.html",
    },
    {
      title: "Rock Paper Scissors",
      description: "Play Against Computer",
      imgUrl: gameImg2,
      link: "https://koushik.stsltd.in/game-stone/",
    },
    {
      title: "Memory Game",
      description: "Speedy Challenge",
      imgUrl: gameImg3,
      link: "https://koushik.stsltd.in/memory-card/",
    },
  ];

  const buisnessPr = [
    {
      title: "Medilogix",
      description: "Buisness Website",
      imgUrl: buisnessPrImg1,
      link: "https://medilogix.in",
    },
  ];
  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Projects</h2>
                  <p>
                    Welcome to my Projects section, where I showcase some of my
                    recent work in web design and development. Each project
                    represents a unique journey of creativity and innovation. I
                    will be updating this section every week with 3 different
                    projects.
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">Fusionz</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">GameZone</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Buisness</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {games.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {buisnessPr.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
