// REACT IMPORTS
import { useState, useEffect } from "react";
// BOOTSTRAP FOR CSS
import { Container, Row, Col } from "react-bootstrap";
// HEADER MY PROFILE PICTURE
import headerImg from "../assets/img/myPic.jpg";
// NEXT BUTTON IMAGE
import { ArrowRightCircle } from "react-bootstrap-icons";
// ANIMATION CSS
import "animate.css";
// FOR CHECKING CHILD COMPONENTS IS VISIBLE
import TrackVisibility from "react-on-screen";

export const Banner = () => {
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <span className="tagline">Welcome to my Portfolio</span>
                  <h1>
                    Howdy! It's Koushik Speaking
                    <br />
                    <span
                      className={`txt-rotate ${isVisible ? "rotate-in" : ""}`}
                    >
                      <span className="wrap">Full Stack Devoloper</span>
                    </span>
                  </h1>
                  <p>
                    I'm a dedicated school student who is passionate about
                    technology and enjoys problem-solving. While balancing my
                    studies, I also work part-time as a developer. I find it
                    exciting to create digital solutions and bring ideas to life
                    through coding. Challenges motivate me, and I'm constantly
                    striving to learn and improve in the ever-changing field of
                    web development. Outside of coding and school, I'm an avid
                    karate and cricket player, and I love exploring new hobbies,
                    traveling, and embracing the adventures life has to offer.
                  </p>
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "header_image" : ""}>
                  <img
                    src={headerImg}
                    alt="Header Img"
                    style={{ width: "350px", height: "auto" }}
                  />
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
