// // REACT IMPORTS
// import { useState } from "react";
// // BOOTRSTRAP FOR CSS
// import { Container, Row, Col } from "react-bootstrap";
// // CSS FOR ANIMATION
// import "animate.css";
// // TRACKVISIBILITY FOR CHILD COMPONENT
// import TrackVisibility from "react-on-screen";
// // AXIOS FOR FETCHING DATA
// import axios from "axios";

// export const Contact = () => {
//   // FORM DETAILS GETTING STORED
//   const formInitialDetails = {
//     firstName: "",
//     lastName: "",
//     email: "",
//     phone: "",
//     message: "",
//   };
//   const [formDetails, setFormDetails] = useState(formInitialDetails);
//   const [buttonText, setButtonText] = useState("Send");
//   const [status, setStatus] = useState({});

//   const onFormUpdate = (category, value) => {
//     setFormDetails({
//       ...formDetails,
//       [category]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Send the form data to your Node.js backend using axios
//     axios
//       .post("http://172.105.62.44:8001/sendEmail", formDetails) // Use http:// instead of https:// and make sure your server is running on port 8001
//       .then((response) => {
//         if (response.data.success) {
//           alert("Feedback sent successfully!");
//           // Optionally, reset the form
//           setFormDetails({
//             firstName: "",
//             lastName: "",
//             email: "",
//             phone: "",
//             message: "",
//           });
//         } else {
//           alert("Feedback sending failed. Please try again later.");
//         }
//       })
//       .catch((error) => {
//         console.error("Error sending feedback:", error);
//         alert("An error occurred. Please try again later.");
//       });
//   };
//   return (
//     <section className="contact" id="connect">
//       <Container>
//         <Row className="align-items-center">
//           <TrackVisibility>
//             {({ isVisible }) => (
//               <div
//                 className={isVisible ? "animate__animated animate__fadeIn" : ""}
//               >
//                 <h2>Get In Touch</h2>
//                 <form onSubmit={handleSubmit}>
//                   <Row>
//                     <Col size={12} sm={6} className="px-1">
//                       <input
//                         type="text"
//                         value={formDetails.firstName}
//                         placeholder="First Name"
//                         onChange={(e) =>
//                           onFormUpdate("firstName", e.target.value)
//                         }
//                       />
//                     </Col>
//                     <Col size={12} sm={6} className="px-1">
//                       <input
//                         type="text"
//                         value={formDetails.lasttName}
//                         placeholder="Last Name"
//                         onChange={(e) =>
//                           onFormUpdate("lastName", e.target.value)
//                         }
//                       />
//                     </Col>
//                     <Col size={12} sm={6} className="px-1">
//                       <input
//                         type="email"
//                         value={formDetails.email}
//                         placeholder="Email Address"
//                         onChange={(e) => onFormUpdate("email", e.target.value)}
//                       />
//                     </Col>
//                     <Col size={12} sm={6} className="px-1">
//                       <input
//                         type="tel"
//                         value={formDetails.phone}
//                         placeholder="Phone No."
//                         onChange={(e) => onFormUpdate("phone", e.target.value)}
//                       />
//                     </Col>
//                     <Col size={12} className="px-1">
//                       <textarea
//                         rows="6"
//                         value={formDetails.message}
//                         placeholder="Message"
//                         onChange={(e) =>
//                           onFormUpdate("message", e.target.value)
//                         }
//                       ></textarea>
//                       <button
//                         type="submit"
//                         style={{ width: "100%", borderRadius: "10px" }}
//                       >
//                         <span>{buttonText}</span>
//                       </button>
//                     </Col>
//                     {status.message && (
//                       <Col>
//                         <p
//                           className={
//                             status.success === false ? "danger" : "success"
//                           }
//                         >
//                           {status.message}
//                         </p>
//                       </Col>
//                     )}
//                   </Row>
//                 </form>
//               </div>
//             )}
//           </TrackVisibility>
//         </Row>
//       </Container>
//     </section>
//   );
// };

// REACT IMPORTS
import React, { useState } from "react";
// BOOTSTRAP FOR CSS
import { Container, Row, Col } from "react-bootstrap";
// CSS FOR ANIMATION
import "animate.css";
// TRACKVISIBILITY FOR CHILD COMPONENT
import TrackVisibility from "react-on-screen";
// AXIOS FOR FETCHING DATA
import axios from "axios";

export const Contact = () => {
  // FORM DETAILS GETTING STORED
  const formInitialDetails = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Send");
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send the form data to your Node.js backend using axios
    axios
      .post("https://172.105.62.44:8001/sendEmail", formDetails)
      .then((response) => {
        if (response.data.success) {
          alert("Feedback sent successfully!");
          // Optionally, reset the form
          setFormDetails({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: "",
          });
        } else {
          alert("Feedback sending failed. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error sending feedback:", error);
        alert("An error occurred. Please try again later.");
      });
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <TrackVisibility>
            {({ isVisible }) => (
              <div
                className={isVisible ? "animate__animated animate__fadeIn" : ""}
              >
                <h2>Get In Touch</h2>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} sm={6} className="px-1">
                      <input
                        type="text"
                        value={formDetails.firstName}
                        placeholder="First Name"
                        onChange={(e) =>
                          onFormUpdate("firstName", e.target.value)
                        }
                      />
                    </Col>
                    <Col xs={12} sm={6} className="px-1">
                      <input
                        type="text"
                        value={formDetails.lastName}
                        placeholder="Last Name"
                        onChange={(e) =>
                          onFormUpdate("lastName", e.target.value)
                        }
                      />
                    </Col>
                    <Col xs={12} sm={6} className="px-1">
                      <input
                        type="email"
                        value={formDetails.email}
                        placeholder="Email Address"
                        onChange={(e) => onFormUpdate("email", e.target.value)}
                      />
                    </Col>
                    <Col xs={12} sm={6} className="px-1">
                      <input
                        type="tel"
                        value={formDetails.phone}
                        placeholder="Phone No."
                        onChange={(e) => onFormUpdate("phone", e.target.value)}
                      />
                    </Col>
                    <Col xs={12} className="px-1">
                      <textarea
                        rows="6"
                        value={formDetails.message}
                        placeholder="Message"
                        onChange={(e) =>
                          onFormUpdate("message", e.target.value)
                        }
                      ></textarea>
                      <button
                        type="submit"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <span>{buttonText}</span>
                      </button>
                    </Col>
                    {status.message && (
                      <Col>
                        <p
                          className={
                            status.success === false ? "danger" : "success"
                          }
                        >
                          {status.message}
                        </p>
                      </Col>
                    )}
                  </Row>
                </form>
              </div>
            )}
          </TrackVisibility>
        </Row>
      </Container>
    </section>
  );
};
