// REACT IMPORTS
import React from "react";
// BOOTSTRAP FOR CSS
import { Container, Row, Col } from "react-bootstrap";
// LOGO SVG
import logo from "../assets/img/logo.png";
// NAV ICONS
import githubIcon from "../assets/img/github-icon.svg";
import instagramIcon from "../assets/img/instagram-icon.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col xs={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon-footer">
              <a href="#">
                <img src={githubIcon} alt="Icon" />
              </a>

              <a href="https://instagram.com/ite.me.kou">
                <img src={instagramIcon} alt="Icon" />
              </a>
            </div>
            <p className="mb-0">Copyright 2022. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
